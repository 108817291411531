import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Modal from '../Modal';
import { useAuth } from '../../hooks/AuthContext';
import Lightbulb from '@mui/icons-material/Lightbulb';

const useStyles = makeStyles({
  button: {
    color: 'var(--caren-icongray)',
    backgroundColor: 'var(--caren-greenblue)',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '250px',
    height: '40px',
    margin: '0 auto',
    padding: '0 20px',
    borderRadius: '5px',
    textTransform: 'uppercase',
    transitionProperty: 'background-color',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease',
    '&:hover': {
      backgroundColor: 'var(--caren-darkgreen)'
    }
  }
});

const SessionUpdatedDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const { t } = useTranslation(['common']);

  const { credentials, reloadSession } = useAuth();

  const handleStorageUpdate = event => {
    if (event.key === '@Caren:session') {
      const { newValue } = event;

      if (
        (newValue == null && credentials?.session?.key != null) ||
        (newValue != null &&
          JSON.parse(newValue)?.session?.key !== credentials?.session?.key)
      ) {
        setIsLogout(newValue == null);
        setOpen(true);
      }
    }

    if (event.key === '@Caren:token') {
      console.info('Reloading session due to change in token');
      reloadSession();
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageUpdate);

    return () => {
      window.removeEventListener('storage', handleStorageUpdate);
    };
  });

  const handleReload = () => {
    reloadSession();
    setOpen(false);
  };

  return (
    <Modal
      handleOpenModal={() => ''}
      openModal={open}
      modalTitle={
        isLogout ? t('Você saiu de sua conta') : t('Você fez um novo login')
      }
      modalWidth="40%"
      modalTitleColor="black"
      containerStyles={{ textAlign: 'center' }}
      noClosing
    >
      <div className="flex w-full justify-center text-5xl">
        <Lightbulb fontSize="inherit" />
      </div>
      <p className="my-8">
        {t(
          'Percebemos uma mudança no acesso neste navegador. Para retomar a sua navegação, clique no botão abaixo.'
        )}
      </p>
      <button
        className={classes.button}
        type="button"
        onClick={() => {
          handleReload();
        }}
      >
        Atualizar
      </button>
    </Modal>
  );
};

export default SessionUpdatedDialog;
